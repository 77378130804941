import type {
  IManifest,
  WorkflowResource,
  WorkflowResources,
} from 'src/app/workflow/services/workflow-gateway/workflow-gateway.type';
import { EResourceType } from 'src/app/workflow/services/workflow-gateway/workflow-gateway.type';
import type { Graphs, GraphSchemaVersion } from '../../graph-editor/models/types';

export type ResourceNavData = string | Graphs;
export type ResourceEditMode = 'graph' | 'text';

export class ResourceNav {
  public name: string;
  public icon: string;
  public isMain: boolean;
  public type: EResourceType;
  public schema: GraphSchemaVersion;

  private _editMode: ResourceEditMode;
  private _modified: boolean;
  private _originalChecksum: string;
  private _resource?: WorkflowResource;
  private _modifiedValue?: ResourceNavData;

  public get editMode(): ResourceEditMode {
    return this._editMode;
  }

  public set editMode(value: ResourceEditMode) {
    if (this.isAgentGraph && value !== this._editMode) {
      this._editMode = value;
      this._updateGraphData(this._modifiedValue);
    }
  }

  public get isEditModeText(): boolean {
    return this._editMode === 'text';
  }

  public get isEditModeGraph(): boolean {
    return this._editMode === 'graph';
  }

  public get isAgentGraph(): boolean {
    return this.type === EResourceType.agentGraph;
  }

  public get isManifest(): boolean {
    return this.type === EResourceType.manifest;
  }

  public get isNodeDefinition(): boolean {
    return this.type === EResourceType.nodeDefinition;
  }

  public get isJavaScript(): boolean {
    return this.type === EResourceType.javascript;
  }

  public get isAsset(): boolean {
    const resource = this.getWorkflowResource();

    let contentLinkIsUrl = true;
    try {
      new URL(resource.contentLink);
    } catch (error) {
      contentLinkIsUrl = false;
    }

    return contentLinkIsUrl && resource.id && !resource.data && this.type === EResourceType.other;
  }

  public get originalValue(): string {
    return this._resource.data;
  }

  public set originalValue(v: string) {
    this._modified = false;
    this._resource.data = this._dataToString(v);
  }

  public get currentValue(): ResourceNavData {
    return this._modifiedValue;
  }

  public set currentValue(v: ResourceNavData) {
    if (this.isAgentGraph && this.editMode === 'graph') {
      const graphs = v as Graphs;
      this._modified = graphs.uiGraph?.checksum !== this._originalChecksum;
    } else {
      this._modified = this.originalValue !== v;
    }
    this._modifiedValue = v;
  }

  public get currentValueAsGraphs(): Graphs {
    if (this.isAgentGraph && this._modifiedValue) {
      if (this.editMode === 'text') {
        try {
          return JSON.parse(this._modifiedValue as string);
        } catch (error) {
          console.warn('Unable to parse graph: ', error);
          return null;
        }
      }
      return this._modifiedValue as Graphs;
    }
    return undefined;
  }

  public get currentValueAsManifest(): IManifest {
    if (this.isManifest && this.originalValue) {
      return JSON.parse(this.originalValue as string) as IManifest;
    }
    return undefined;
  }

  public get currentValueAsPrettyString(): string {
    const strValue = this._dataToString(this._modifiedValue);
    if (this.type === EResourceType.javascript) return strValue;
    return JSON.stringify(JSON.parse(strValue), null, 2);
  }

  public get isModified(): boolean {
    return this._modified;
  }

  public get extension(): string {
    return this.name.split('.').pop() === 'json' ? 'json' : 'javascript';
  }

  public get baseName(): string {
    return this.name.split('.').shift();
  }

  public constructor(wr: WorkflowResource, name: string) {
    this.name = name;
    this.type = wr.type;
    this.icon = ResourceNav.iconForType(this.type);

    this._modified = false;
    this._resource = wr;
    this._resetEditMode();

    this.setResourceData(wr.data);
  }

  public static iconForType(type: EResourceType): string {
    switch (type) {
      case EResourceType.agentGraph:
        return 'ri-workflow-graph';
      case EResourceType.javascript:
        return 'ri-workflow-javascript';
      case EResourceType.manifest:
        return 'ri-workflow-json';
      case EResourceType.nodeDefinition:
        return 'ri-workflow-json';
      default:
        return 'ri-workflow-file';
    }
  }

  public setResourceData(data: string): void {
    if (!data) return;
    this._resource.data = data;

    if (!this.isAgentGraph) {
      this._modifiedValue = data;
      return;
    }

    let graphs: Graphs;
    try {
      graphs = JSON.parse(data) as Graphs;
    } catch (error) {
      this.editMode = 'text';
      this._modifiedValue = data;
      return;
      // throw new Error('Graph data is not valid JSON'); // ?
    }

    if (!graphs.uiGraph) {
      this.editMode = 'text';
      this._modifiedValue = data;
      return;
      // throw new Error('Graph data is is missing uiGraph'); // ?
    }

    this.schema = graphs.uiGraph?.schema;
    this._originalChecksum = graphs.uiGraph?.checksum;
    this._updateGraphData(graphs);
  }

  public getWorkflowResource(): WorkflowResource {
    return this._resource;
  }

  public exportToWorkflowResources(fromModifiedValue = false): WorkflowResources {
    const resource: WorkflowResource = fromModifiedValue
      ? { ...this._resource, data: this._dataToString(this._modifiedValue) }
      : this._resource;
    return {
      [this.name]: resource,
    };
  }

  public isDataFetched(): boolean {
    return !!this._modifiedValue;
  }

  public persistChange(): ResourceNav {
    if (this._modifiedValue !== undefined) {
      if (this.isAgentGraph) {
        const graphs = this._modifiedValue as Graphs;
        this._originalChecksum = graphs.uiGraph?.checksum;
      }
      // Validate JSON before persisting
      if (this.isManifest || this.isNodeDefinition) {
        try {
          JSON.parse(this._modifiedValue as string);
        } catch (error) {
          throw new Error(`'${this.name}' content is not valid JSON.`);
        }
      }

      this.originalValue = this._dataToString(this._modifiedValue);
    }
    return this;
  }

  public discardChanges(): ResourceNav {
    this._resetEditMode();
    this.setResourceData(this.originalValue);
    this._modified = false;
    return this;
  }

  private _resetEditMode(): void {
    this._editMode = this.isAgentGraph ? 'graph' : 'text';
  }

  private _dataToString(v: ResourceNavData): string {
    return typeof v === 'string' ? v : JSON.stringify(v);
  }

  private _updateGraphData(data: ResourceNavData): void {
    if (this.isAgentGraph && data) {
      const isString = typeof data === 'string';
      if (this._editMode === 'graph') {
        this._modifiedValue = isString ? JSON.parse(data as string) : data;
      }

      if (this._editMode === 'text') {
        this._modifiedValue = isString ? data : JSON.stringify(data, null, 2);
      }
    }
  }
}
