import type { Config, UrlsConfig } from './base';
import packageJson from '../../package.json';

// ====================
// This is a shared environment file for providing default
// settings to other environment.

export const defaultEnvironment: Config = {
  name: 'dev',
  testHooksEnabled: false,
  fullyQualifiedLoginRedirectsEnabled: false,
  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1Ijoibmlja3BvbmxpbmUiLCJhIjoiY2xqNHAxank1MDQxMTNndDVrN25yczZidCJ9.dFYFQFufjyvXLtuOJGZZHQ',
  },
  google: {
    apiKey: 'AIzaSyAssYj2xmGswifkND-e1rbO7eBc5ZLW91A',
  },
  courier: {
    clientKey: '',
  },
  datadog: {
    applicationId: '',
    clientToken: '',
    service: 'gr-console',
    sampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  },
  version: packageJson.version as string,
  routing: {
    enableTracing: false,
    useHash: false,
  },
  links: {
    docs: 'https://docs-automate.dronedeploy.com/',
    docsStreamingData: 'https://docs-automate.dronedeploy.com/robotics-toolkit/general-usage/streaming-telemetry-data',
    docsConnectRobot: 'https://docs-automate.dronedeploy.com/robotics-toolkit/getting-started/connect-your-own-robot/',
    docsControls: 'https://docs-automate.dronedeploy.com/robotics-toolkit/getting-started/connect-your-own-robot/',
    docsCommands: 'https://docs-automate.dronedeploy.com/robotics-toolkit/general-usage/controlling-a-robot/commands',
    docsDashboard: 'https://docs-automate.dronedeploy.com/robotics-toolkit/general-usage/dashboards/',
    dd: 'https://www.dronedeploy.com',
  },
  defaultDomain: 'dronedeploy.com',
  contents: {
    agentConnectScripts:
      'echo "deb https://packages.rocos.io/apt stable main" | sudo tee /etc/apt/sources.list.d/rocos.list \ncurl https://packages.rocos.io/apt/docs/key.gpg | sudo apt-key add - \nsudo apt-get update && sudo apt-get install rocos-agent -y',
  },
  api: {
    url: 'https://dev-api-automate.dronedeploy.com',
    grpcUrl: 'https://dev-api-automate.dronedeploy.com',
  },
  allowSignUp: true,
  defaultSidebarStatus: 'collapsed',
  i18n: {
    transloco: {
      availableLangs: ['en', 'zh-CN'],
      defaultLang: 'en',
      fallbackLang: 'en',
    },
    autoDetectLanguageAndSwitch: true,
  },
  p2pSource: {
    iceServers: [
      {
        urls: ['turn:turn.rocos.io:443?transport=udp'],
        username: 'turn-user',
        credential: 'i8NjnZ0fw7r@hm7U',
      },
      {
        urls: ['turn:turn.rocos.io:443?transport=tcp'],
        username: 'turn-user',
        credential: 'i8NjnZ0fw7r@hm7U',
      },
    ],
  },
  intercom: {
    appId: 'sri7v2cs',
    enabled: true,
  },
  launchDarkly: {
    clientId: '5a1f047a32fd110d47ea1f46',
  },
  djiDock: {
    mqtt: {
      protocol: 'mqtts://',
      host: 'dji-dock-us.dronedeploy.com',
      port: '8883',
    },
    stream: {
      url: 'rtmps://rtmp-us.dronedeploy.com',
      port: '1937',
    },
    objectStorage: {
      protocol: 'https://',
      host: 's3-us.dronedeploy.com',
    },
  },
};

export const defaultUrls: UrlsConfig = {
  dashboard: 'dashboard',
  home: '',
  account: {
    index: (accountId: string, inviteCode?: string): string => {
      let url = `account/${accountId}`;
      if (inviteCode) {
        url = `${url}?inviteCode=${inviteCode}`;
      }

      return url;
    },
  },
  noAccount: {
    index: () => {
      return `no-account`;
    },
  },
  switchAccount: {
    index: (): string => {
      return `switch-account`;
    },
  },
  agentCallback: (): string => {
    return `agent`;
  },
  robots: {
    index: (projId: string): string => {
      return `project/${projId}/robots`;
    },
    list: (projId: string): string => {
      return `project/${projId}/robots/list`;
    },
    intro: (projId: string): string => {
      return `project/${projId}/robots/intro`;
    },
    info: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}`;
    },
    dashboard: (projId: string, callsign: string, dashboardId: string): string => {
      return `project/${projId}/robots/${callsign}/dashboard/${dashboardId}`;
    },
    controls: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/control`;
    },
    buttons: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/buttons`;
    },
    gamepads: (projId: string, callsign: string) => {
      return `project/${projId}/robots/${callsign}/gamepads`;
    },
    settings: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/settings`;
    },
    events: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/events`;
    },
    commands: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/commands`;
    },
    storageStreams: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/data-stream`;
    },
    dataViewer: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/data-viewer`;
    },
    files: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/files`;
    },
    shell: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/shell`;
    },
    automate: (projId: string, callsign: string): string => {
      return `project/${projId}/robots/${callsign}/automate`;
    },
    page: (projId: string, callsign: string, page: string): string => {
      return `project/${projId}/robots/${callsign}/${page}`;
    },
  },
  project: {
    index: (projId: string) => {
      return `project/${projId}`;
    },
    settings: (projId: string) => {
      return `project/${projId}/settings`;
    },
  },
  streams: {
    index: (projId: string): string => {
      return `project/${projId}/streams`;
    },
    info: (projId: string, streamId: string): string => {
      return `project/${projId}/streams/${streamId}`;
    },
  },
  operations: {
    index: (projId: string): string => {
      return `project/${projId}/operations`;
    },
    global: (projId: string): string => {
      return `project/${projId}/operations/global`;
    },
    local: (projId: string): string => {
      return `project/${projId}/operations/local`;
    },
    viz: (projId: string): string => {
      return `project/${projId}/operations/viz`;
    },
    list: (projId: string): string => {
      return `project/${projId}/operations/list`;
    },
    detail: (projId: string, pageId: string, pageType: string): string => {
      return `project/${projId}/operations/${pageType}/${pageId}`;
    },
  },
  environment: {
    index: (projId: string): string => {
      return `project/${projId}/environment`;
    },
  },
  playground: {
    elasticsearch: (projId?: string) => {
      return `project/${projId}/playground/elasticsearch`;
    },
  },
  analyze: {
    dataExplorer: (projId?: string) => {
      return `project/${projId}/analyze/data-explorer`;
    },
  },
  robotDefinitions: {
    index: (projId: string) => {
      return `project/${projId}/robot-profiles`;
    },
    details: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}`;
    },
    settings: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/settings`;
    },
    events: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/events`;
    },
    commands: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/commands-v2`;
    },
    controls: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/controls`;
    },
    buttons: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/buttons`;
    },
    gamepads: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/gamepads`;
    },
    storageStreams: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/data-stream`;
    },
    dashboards: (projId: string, defId: string) => {
      return `project/${projId}/robot-profiles/${defId}/dashboard`;
    },
    dashboard: (projId: string, callsign: string, dashboardId: string) => {
      return `project/${projId}/robot-profiles/${callsign}/dashboard/${dashboardId}`;
    },
    page: (projId: string, defId: string, page: string): string => {
      return `project/${projId}/robot-profiles/${defId}/${page}`;
    },
  },
  functions: {
    index: (projId: string) => {
      return `project/${projId}/functions`;
    },
    details: (projId: string, funcId: string) => {
      return `project/${projId}/functions/${funcId}`;
    },
  },
  automate: {
    index: (projId: string): string => {
      return `project/${projId}/automate`;
    },
    detail: (projId: string, workflowId: string): string => {
      return `project/${projId}/automate/${workflowId}`;
    },
  },
  storageStreams: {
    index: (projId: string): string => {
      return `project/${projId}/streams`;
    },
  },
  storageExplorer: {
    index: (projId: string): string => {
      return `project/${projId}/analyze/data-explorer`;
    },
  },
};
