import type { Config, UrlsConfig } from './base';
import { defaultEnvironment, defaultUrls } from './default';
import packageJson from '../../package.json';

const version = packageJson.version as string;

export const environment: Config = {
  name: 'prod',
  testHooksEnabled: false,
  production: true,
  fullyQualifiedLoginRedirectsEnabled: false,
  mapbox: {
    ...defaultEnvironment.mapbox,
  },
  google: {
    apiKey: 'AIzaSyAssYj2xmGswifkND-e1rbO7eBc5ZLW91A',
  },
  courier: {
    clientKey: 'ZjUxMmQ5OGYtY2UxZC00Mjg0LWIwNTktOTRkZWI2OTIzZTc1',
  },
  datadog: {
    ...defaultEnvironment.datadog,
    clientToken: 'pub8d3543b8bee4be1f71767a74f2b81aba',
    applicationId: '0e430b83-99a1-484e-8e45-f1fb12991bb8',
    env: 'prod',
    site: 'datadoghq.com',
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    version,
  },
  version,
  routing: {
    enableTracing: false,
    useHash: false,
  },
  links: defaultEnvironment.links,
  contents: defaultEnvironment.contents,
  defaultDomain: defaultEnvironment.defaultDomain,
  api: {
    url: 'https://api-automate.dronedeploy.com',
    grpcUrl: 'https://api-automate.dronedeploy.com',
  },
  allowSignUp: true,
  defaultSidebarStatus: 'collapsed',
  i18n: {
    transloco: {
      availableLangs: ['en', 'zh-CN'],
      defaultLang: 'en',
      fallbackLang: 'en',
    },
    autoDetectLanguageAndSwitch: false, // Still working on this one, turn this feature off for production.
  },
  p2pSource: {
    iceServers: [
      {
        urls: ['turn:turn.rocos.io:443?transport=udp'],
        username: 'turn-user',
        credential: 'i8NjnZ0fw7r@hm7U',
      },
      {
        urls: ['turn:turn.rocos.io:443?transport=tcp'],
        username: 'turn-user',
        credential: 'i8NjnZ0fw7r@hm7U',
      },
    ],
  },
  intercom: defaultEnvironment.intercom,
  webComponents: {
    location: 'https://components-automate.dronedeploy.com/web-components.js',
  },
  droneDeploy: {
    thumbnailApi: 'https://tiles_2_rt_prod.dronedeploy.com/images/thumb/',
    tilesApi: 'https://tiles_2_rt_prod.dronedeploy.com/unsafe/smart/',
  },
  launchDarkly: {
    clientId: '5a1f047a32fd110d47ea1f47',
  },
  djiDock: {
    mqtt: {
      protocol: 'mqtts://',
      host: 'dji-dock-us.dronedeploy.com',
      port: '8883',
    },
    stream: {
      url: 'rtmps://rtmp-us.dronedeploy.com',
      port: '1937',
    },
    objectStorage: {
      protocol: 'https://',
      host: 's3-us.dronedeploy.com',
    },
  },
};

export const urls: UrlsConfig = defaultUrls;
