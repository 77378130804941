import { Injectable } from '@angular/core';
import { AppService, RocosSdkClientService, ToastService } from '@shared/services';
import { Utils } from '@shared/utils';
import { saveFileFromBlob, saveFileWithUrl } from '@shared/utils/file-utils';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { cBaseAgentGraph } from 'src/app/workflow/services/workflow-gateway/templates/cBaseAgentGraph';
import { WorkflowGatewayService } from 'src/app/workflow/services/workflow-gateway/workflow-gateway.service';
import type {
  WorkflowResource,
  WorkflowResources,
} from 'src/app/workflow/services/workflow-gateway/workflow-gateway.type';
import { EResourceType } from 'src/app/workflow/services/workflow-gateway/workflow-gateway.type';
import { CDefaultNodeEntryKey, CDefaultNodeTriggerInKey } from '../../graph-editor/mockup/node';
import { ResourcesUtils } from '../../graph-editor/utils/resource-utils';
import type { ResourceNavData } from '../models/resource-nav';
import { ResourceNav } from '../models/resource-nav';

@Injectable({
  providedIn: 'root',
})
export class ResourcesStateService {
  private _flowId: string;
  private _resources$: BehaviorSubject<ResourceNav[]> = new BehaviorSubject(null);
  private get currentResources(): ResourceNav[] {
    return this._resources$.getValue() || [];
  }
  private _selected$: BehaviorSubject<ResourceNav | null> = new BehaviorSubject(null);

  public constructor(
    private workflowGateway: WorkflowGatewayService,
    private toast: ToastService,
    private sdk: RocosSdkClientService,
    private app: AppService,
  ) {}

  private set nextResources(resources: ResourceNav[]) {
    this._resources$.next(
      resources.sort((a: ResourceNav, b: ResourceNav) => {
        if (a.isManifest) return -1;
        if (b.isManifest) return 1;
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      }),
    );
  }

  public get resources$(): Observable<ResourceNav[]> {
    return this._resources$.asObservable();
  }

  public get selected$(): Observable<ResourceNav> {
    return this._selected$.asObservable();
  }

  public getResourceByName(name: string): Observable<ResourceNav> {
    return of(this.getResourceValueByName(this.currentResources, name));
  }

  public reset(): void {
    this._resources$.next(null);
    this._selected$.next(null);
  }

  ////////////////////////////////////////////////////////////
  // IMPORTS
  ////////////////////////////////////////////////////////////

  public setResources(newWorkflowResources: WorkflowResources, flowId: string): void {
    this._flowId = flowId;
    const existingResources = this.currentResources;

    // Start from scratch:
    if (existingResources.length === 0) {
      const resources = Object.keys(newWorkflowResources).map((k) => new ResourceNav(newWorkflowResources[k], k));
      this.nextResources = this.upgradeLegacyGraphs(resources);
      this.selectMainGraph(this.currentResources);
      return;
    }

    // We have existing resources, merge in the new stuff:
    const existingKeys = existingResources.map((rn) => rn.name);
    const newKeys = Object.keys(newWorkflowResources);
    const brandNewKeys = newKeys.filter((key) => !existingKeys.includes(key));
    const prunedResources = existingResources.filter((resource) => newKeys.includes(resource.name));
    const newResources = brandNewKeys.map((k) => new ResourceNav(newWorkflowResources[k], k));
    this.nextResources = [...prunedResources, ...newResources];
  }

  ////////////////////////////////////////////////////////////
  // EDITOR
  ////////////////////////////////////////////////////////////

  public selectResource(name: string): void {
    const resource = this.getResourceValueByName(this.currentResources, name);
    if (!resource || resource.type === EResourceType.other) return;

    // Used when updating individual resources
    if (!resource.isDataFetched()) {
      this.workflowGateway
        .getOneResource(resource, this._flowId)
        .pipe(first())
        .subscribe(
          (r) => {
            resource.setResourceData(r.data);
            this._selected$.next(resource);
          },
          (_err) => {
            this.toast.short(`Error while fetching resource ${name}`, null, 'error');
          },
        );
    } else {
      this._selected$.next(resource);
    }
  }

  public toggleEditMode(name: string): void {
    const resource = this.getResourceValueByName(this.currentResources, name);
    if (resource.isAgentGraph) {
      resource.editMode = resource.isEditModeGraph ? 'text' : 'graph';
      this.selectResource(name);
    }
  }

  public resetGraph(name: string): void {
    const resource = this.getResourceValueByName(this.currentResources, name);
    if (resource.isAgentGraph) {
      resource.currentValue = cBaseAgentGraph;
      resource.persistChange();
      resource.editMode = 'graph';
      this.selectResource(name);
    }
  }

  public closeResource(name: string, nextName: string = null): void {
    this.getResourceValueByName(this.currentResources, name)?.discardChanges();
    if (nextName) {
      this.selectResource(nextName);
    } else {
      this._selected$.next(null);
    }
  }

  public discardResource(name: string): Observable<any> {
    this.getResourceValueByName(this.currentResources, name)?.discardChanges();
    this.nextResources = this.currentResources ?? [];
    return of(true);
  }

  public exportResources(fromModifiedValue = false): WorkflowResources {
    const resources = this.currentResources;
    return resources.reduce<WorkflowResources>(
      (wr, r) => ({
        ...wr,
        ...r.exportToWorkflowResources(fromModifiedValue),
      }),
      {},
    );
  }

  public setSelectedResourceValue(value: ResourceNavData) {
    const resource = this._selected$.getValue();
    if (!resource) return;

    if (resource.isAgentGraph && resource.editMode === 'graph') {
      const prevModified = resource.isModified;
      resource.currentValue = value;
      // Only update selected if modified has changed
      if (prevModified !== resource.isModified) {
        this._selected$.next(resource);
      }
    } else {
      resource.currentValue = value;
      this._selected$.next(resource);
    }
  }

  ////////////////////////////////////////////////////////////
  // EXPORT
  ////////////////////////////////////////////////////////////

  public download(name: string): void {
    const resource = this.getResourceValueByName(this.currentResources, name);
    if (!resource) return;

    if (resource.type === EResourceType.other) {
      const url = resource.getWorkflowResource().contentLink;
      saveFileWithUrl(url, name);
      return;
    }

    const blob = new Blob([resource.currentValueAsPrettyString], {
      type: 'text/json',
    });

    saveFileFromBlob(blob, `${this._flowId}_${name}`);
  }

  ////////////////////////////////////////////////////////////
  // SAVING
  ////////////////////////////////////////////////////////////

  public saveSelectedResource(): Observable<WorkflowResource> {
    return this.selected$.pipe(
      first(),
      switchMap((resource) => {
        return this.saveResourceByName(resource.name);
      }),
    );
  }

  public saveResourceByName(name: string): Observable<WorkflowResource> {
    return this.getResourceByName(name).pipe(
      Utils.rxjsNullFilter,
      take(1),
      map((resource: ResourceNav) => {
        return resource.persistChange().getWorkflowResource();
      }),
      switchMap((resourceToSave) => {
        return this.updateSingleResource(resourceToSave);
      }),
    );
  }

  public addResource(resource: WorkflowResource): Observable<WorkflowResources> {
    const resources: WorkflowResources = {
      ...this.exportResources(),
      [resource.path]: resource,
    };

    return this.updateResources(resources).pipe(
      tap((res) => {
        this.setResources(res, this._flowId);
        this.selectResource(resource.path);
      }),
    );
  }

  public replaceResource(oldResource: WorkflowResource, newResource: WorkflowResource): Observable<WorkflowResources> {
    const resources: WorkflowResources = {
      ...this.exportResources(),
      [newResource.path]: newResource,
    };
    delete resources[oldResource.path];

    return this.updateResources(resources).pipe(
      tap((res) => {
        this.setResources(res, this._flowId);
        this.selectMainGraph(this.currentResources);
      }),
    );
  }

  public async removeResource(resource: WorkflowResource): Promise<WorkflowResources> {
    const resources: WorkflowResources = {
      ...this.exportResources(),
    };
    delete resources[resource.path];

    const newResources = await firstValueFrom(this.updateResources(resources));

    this.setResources(newResources, this._flowId);
    this.selectMainGraph(this.currentResources);

    return newResources;
  }

  public async deleteAsset(resource: WorkflowResource): Promise<void> {
    if (!resource.id) {
      throw new Error('cannot delete asset without an id');
    }

    await this.sdk.client.getWorkflowService().deleteAsset(this.app.projectId, this._flowId, resource.id);

    const resources: WorkflowResources = {
      ...this.exportResources(),
    };

    delete resources[resource.path];
    this.setResources(resources, this._flowId);
  }

  public saveAllResources(): Observable<WorkflowResources> {
    return of(this.currentResources).pipe(
      map((resources) => {
        resources.forEach((resource) => {
          resource.persistChange();
        });
        this.nextResources = resources;
      }),
      switchMap((_resources) => {
        const workflowResources = this.exportResources();
        return this.updateResources(workflowResources);
      }),
    );
  }

  private updateSingleResource(resource: WorkflowResource): Observable<WorkflowResource> {
    return this.workflowGateway.updateSingleWorkflowResource(resource, this._flowId).pipe(
      tap((_workflowResource) => {
        // If manifest has been updated, update selected
        const selected = this._selected$.getValue();
        if (resource.path === ResourcesUtils.Manifest) {
          this._selected$.next(selected);
        }
      }),
    );
  }

  private updateResources(resources: WorkflowResources): Observable<WorkflowResources> {
    return this.workflowGateway.updateWorkflowResources(resources, this._flowId);
  }

  ////////////////////////////////////////////////////////////
  // UTILS
  ////////////////////////////////////////////////////////////

  private upgradeLegacyGraphs(resources: ResourceNav[]): ResourceNav[] {
    const graphResources = this.getResourcesByType(resources, EResourceType.agentGraph);

    graphResources.forEach((resource) => {
      try {
        const graphs = resource.currentValueAsGraphs;
        const nodes = Object.values(graphs.uiGraph.nodes);

        // Convert all legacy entry nodes to triggerIn nodes
        // gnarly, but we do this in place...
        const entryNode = nodes.find((node) => node.name === CDefaultNodeEntryKey);
        if (entryNode) {
          entryNode.name = CDefaultNodeTriggerInKey;
          entryNode.outputs = {
            out: entryNode.outputs['Out'],
          };
        }
      } catch (error) {
        console.warn('Unable to upgrade graph:', error);
      }
    });

    return resources;
  }

  private getResourcesByType(resources: ResourceNav[], type: EResourceType): ResourceNav[] {
    return resources.filter((r) => r.type === type);
  }

  private getResourceValueByName(resources: ResourceNav[], name: string): ResourceNav {
    return resources.find((r) => r.name === name);
  }

  private selectMainGraph(resources: ResourceNav[]): void {
    const manifest = resources.find((res) => res.name === ResourcesUtils.Manifest).currentValueAsManifest;
    const graph = (manifest.signals?.main?.entry?.[0] || manifest.main?.[0]) + '.graph';
    this.selectResource(graph);
  }
}
