import type { OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RocosClientService } from '@shared/services';
import { WidgetBaseComponent } from '../shared/widget-base';
import type { IRocosSDKConfigToken } from '@dronedeploy/rocos-js-sdk';

@UntilDestroy()
@Component({
  selector: 'app-widget-web-component',
  templateUrl: './widget-web-component.component.html',
  styleUrls: ['./widget-web-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWebComponentComponent extends WidgetBaseComponent implements OnChanges, AfterViewInit {
  @Input() public elementName: string;
  @Input() public componentConfig: Record<string, any>;
  @Input() public projectId: string;
  @Input() public callsign: string;

  @ViewChild('placeholder') public placeholder: ElementRef;

  private token: string;
  private customElement: HTMLElement;

  constructor(private clientService: RocosClientService) {
    super();

    this.theme = 'none';
    this.clientService.token$.pipe(untilDestroyed(this)).subscribe((token) => {
      this.token = token;
      this.createOrUpdateElement();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['elementName'] || changes['componentConfig'] || changes['projectId'] || changes['callsign']) {
      this.createOrUpdateElement();
    }
  }

  ngAfterViewInit() {
    if (this.elementName) {
      this.createOrUpdateElement();
    }
  }

  private get sdkOptions(): IRocosSDKConfigToken {
    return {
      url: environment.api.url.replace('https://', ''),
      token: this.token,
    };
  }

  private createOrUpdateElement() {
    if (!this.placeholder || !this.elementName) return;

    if (this.customElement && this.customElement.tagName !== this.elementName.toUpperCase()) {
      this.customElement.remove();
      this.customElement = null;
    }

    if (!this.customElement) {
      this.customElement = document.createElement(this.elementName);
    }

    const el = this.customElement;

    el.setAttribute('sdk', JSON.stringify(this.sdkOptions));
    el.setAttribute('callsign', this.callsign);
    el.setAttribute('projectid', this.projectId);
    el.setAttribute('config', JSON.stringify(this.componentConfig));

    this.placeholder.nativeElement.replaceChildren(el);
  }
}
