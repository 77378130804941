/**
 * @deprecated use ResourceType from @dronedeploy/rocos-sdk-js instead
 */
export enum EResourceType {
  agentGraph = 'AgentGraph', // UIGraph and AgentGraph is in the same file (.graph)
  nodeDefinition = 'NodeDefinition', // JSON (inputs/outputs) (.json)
  manifest = 'Manifest', // Bundle definition (.json)
  javascript = 'Javascript', // node implementations (.js)
  other = 'Other',
}

/**
 * @deprecated use WorkflowBase from @dronedeploy/rocos-sdk-js instead
 */
export interface IUpdateWorkflowPayload {
  name: string;
  description: string;
  resources?: WorkflowResources;
  flowId: string;
  type?: 'cloud';
}

/**
 * @deprecated use Resource from @dronedeploy/rocos-sdk-js instead
 */
export interface WorkflowResource {
  id?: string;
  data: string;
  type: EResourceType;
  size?: number;
  path?: string;
  contentLink?: string;
}

/**
 * @deprecated use ManifestResource from @dronedeploy/rocos-sdk-js instead
 */
export interface IManifestResource {
  path: string;
  provider: string;
  runtime: string;
  options: string[];
}

/**
 * @deprecated use ManifestArgument from @dronedeploy/rocos-sdk-js instead
 */
export interface IManifestArgument {
  default?: string;
  description?: string;
  type: string;
}

/**
 * @deprecated use Manifest from @dronedeploy/rocos-sdk-js instead
 */
export interface IManifest {
  main?: [string, string];
  signals: {
    main: {
      args: string[];
      entry: [string, string];
    };
  };
  // users will manually enter this type, and it may not be valid
  arguments?: unknown | Record<string, IManifestArgument>;
  resources: IManifestResource[];
  mode?: 'cloud';
}

/**
 * @deprecated use Resources from @dronedeploy/rocos-sdk-js instead
 */
export interface WorkflowResources {
  [key: string]: WorkflowResource;
}

/**
 * @deprecated use DeployedFlow from @dronedeploy/rocos-sdk-js instead
 */
export interface IDeployedFlowResponse {
  flowId: string;
  flow?: IWorkflowResponse;
  deployed?: boolean;
}

/**
 * @deprecated use Workflow from @dronedeploy/rocos-sdk-js instead
 */
export interface IWorkflowResponse {
  createdBy: string;
  dateCreated: number;
  dateUpdated: number;
  id: string;
  name: string;
  description: string;
  updatedBy: string;
  flowId: string;
  type: string;
  resources: WorkflowResources;
}

/**
 * @deprecated use WorkflowList from @dronedeploy/rocos-sdk-js instead
 */
export interface IWorkflowListResponse {
  createdBy: string;
  dateCreated: number;
  dateUpdated: number;
  id: string;
  name: string;
  description?: string;
  updatedBy: string;
  flowId: string;
  type?: 'cloud';
  author:
    | {
        id: string;
        name: string;
        email: string;
      }
    | Record<string, never>;
}

/**
 * @deprecated use WorkflowRevision from @dronedeploy/rocos-sdk-js instead
 */
export interface IWorkflowRevisionResponse {
  id: string;
  version: number;
  authorName: string;
  createdBy: string;
  dateCreated: number;
  description: string;
}

/**
 * @deprecated use WorkflowRevision from @dronedeploy/rocos-sdk-js instead
 */
export interface IWorkflowResponseExtended extends IWorkflowListResponse {
  selected: boolean;
}
