import { EResourceType } from 'src/app/workflow/services/workflow-gateway/workflow-gateway.type';

const fileExtensionRegexp = /\.[^.]*$/;
export class ResourcesUtils {
  public static Manifest = 'manifest.json';
  public static NodesDef = 'std-nodes.json';
  public static Javascript = 'std-nodes.js';

  public static AgentGraphName = 'main';
  public static AgentGraphPath = `${this.AgentGraphName}.graph`;

  public static getResourcePath = (nameOrPath: string, type: EResourceType): string => {
    const name = type === EResourceType.other ? nameOrPath : this.getResourceName(nameOrPath);
    switch (type) {
      case EResourceType.agentGraph:
        return `${name}.graph`;
      case EResourceType.nodeDefinition:
        return `${name}.json`;
      case EResourceType.manifest:
        return `manifest.json`;
      case EResourceType.javascript:
        return `${name}.js`;
      case EResourceType.other:
        return `${name}`;
      default:
        throw new Error('Cannot get resource path for invalid EResourceType');
    }
  };

  public static getResourceName = (path: string): string => {
    return path.replace(fileExtensionRegexp, '');
  };

  public static getResourceProvider = (type: EResourceType): string => {
    switch (type) {
      case EResourceType.agentGraph:
      case EResourceType.nodeDefinition:
        return 'visualscript';
      case EResourceType.javascript:
        return 'js';
      case EResourceType.other:
        return 'other';
      default:
        throw new Error('Cannot get resource provider for invalid EResourceType');
    }
  };
}
