<div
  #widgetContainer
  class="box widget-root"
  [class.editing-mode]="editing && !isGroupWidget"
  [class.group-def-editing]="editing && isGroupWidget"
  [class.status-dead]="status === 'dead'"
  [ngClass]="[type, theme]"
  [class.larger]="largerWidth"
  [style.width]="width"
  [style.height]="height"
  (resized)="onWidgetResized()"
>
  <!--
    gridster-item-content: "default content class to ignore the drag event from"
    Ref: https://github.com/tiberiuzuld/angular-gridster2/blob/master/src/assets/drag.md
  -->
  <div class="edit-buttons gridster-item-content" [class.compact-buttons]="smallEditButtons">
    <button
      mat-icon-button
      (click)="onEdit()"
      *ngIf="!smallEditButtons && (type !== 'web-component' || (canEditWebComponent$ | async))"
    >
      <span class="ri-edit"></span>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="moreInfoMenu">
      <span class="ri-more"></span>
    </button>
  </div>

  <mat-menu #moreInfoMenu="matMenu">
    <button mat-menu-item class="short-item" *ngIf="smallEditButtons" (click)="onEdit()">Edit</button>
    <button mat-menu-item class="red-text short-item" (click)="onDelete()">Delete</button>
    <mat-divider></mat-divider>

    <mat-radio-group
      id="layerRadio"
      class="radio-group"
      [(ngModel)]="layerIndex"
      (change)="onBackgroundLayerChanged($event)"
    >
      <label>Layer</label>
      <mat-radio-button class="radio-button" [value]="0">Foreground</mat-radio-button>
      <mat-radio-button class="radio-button" [value]="-1">Background</mat-radio-button>
    </mat-radio-group>
  </mat-menu>

  <div class="editing-layer"></div>

  <div class="fade-out-layer">
    <div class="warning-icon">
      <span class="ri-plugin"></span>
    </div>
  </div>

  <div class="group-def-layer">
    <div>
      <div class="ri-robot-definition"></div>
      <div>Defined in Profile</div>
    </div>
  </div>

  <div
    class="heading widget-heading"
    *ngIf="heading"
    [class.with-icons]="(menuItems && menuItems.length > 0) || (toolbarItems && toolbarItems.length > 0)"
    [class.with-gradient]="type === 'video' || type === 'image' || type === 'ops'"
    [class.not-cover-content]="floorHeading"
  >
    <div class="d-flex align-items-center">
      <div class="inline-block" matTooltipClass="pre-tooltip" [matTooltip]="heading + '&#13;' + (dataURI || '')">
        {{ heading }}&nbsp;
      </div>

      <div class="toolbar-items">
        <ng-container *ngFor="let item of toolbarItems">
          <ng-container *ngIf="item.position === 'left'">
            <ng-container *ngTemplateOutlet="toolbarItemTemplate; context: { item: item }"> </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="d-flex align-items-center right-toolbar-items">
      <!-- Right Toolbar items -->
      <ng-container *ngFor="let item of toolbarItems">
        <ng-container *ngIf="item.position === 'right'">
          <ng-container *ngTemplateOutlet="toolbarItemTemplate; context: { item: item }"> </ng-container>
        </ng-container>
      </ng-container>

      <!-- More menu -->
      <div *ngIf="menuItems && menuItems.length > 0" class="inline-block">
        <div class="more-menu-button" [matMenuTriggerFor]="moreMenu">
          <span class="icon-button ri-more"></span>
        </div>

        <mat-menu #moreMenu="matMenu">
          <button
            class="widget-menu-button"
            [class]="item.cssClass || ''"
            mat-menu-item
            *ngFor="let item of menuItems"
            (click)="onMenuItemClick(item)"
          >
            <span [class]="item.cssClass || ''">
              {{ item.text }}
            </span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #toolbarItemTemplate let-item="item">
  <div class="toolbar-item inline-block" [matTooltip]="item.tooltip" [ngClass]="[item.theme, 'type-' + item.type]">
    <ng-container [ngSwitch]="item.style">
      <!-- Link -->
      <ng-container *ngSwitchCase="'link'">
        <button mat-icon-button class="rs-tiny-icon-button" [routerLink]="item.link">
          <span *ngIf="item.icon" [ngClass]="item.icon"></span>
          <span *ngIf="item.text">{{ item.text }}</span>
        </button>
      </ng-container>

      <!-- Plain -->
      <ng-container *ngSwitchCase="'plain'">
        <span>
          <span *ngIf="item.icon" [ngClass]="item.icon"></span>
          <span *ngIf="item.text">{{ item.text }}</span>
          <span *ngIf="item.asyncText">{{ item.asyncText | async }}</span>
        </span>
      </ng-container>

      <!-- Button -->
      <ng-container *ngSwitchCase="'button'">
        <button
          mat-icon-button
          [ngClass]="item.buttonClass"
          class="rs-tiny-icon-button"
          (click)="onToolbarItemClick(item)"
          type="button"
        >
          <span *ngIf="item.icon" [ngClass]="item.icon"></span>
          <span *ngIf="item.text">{{ item.text }}</span>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
