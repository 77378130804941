import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  AppService,
  ProjectPermissionService,
  RocosClientService,
  RocosSdkClientService,
  UserService,
} from '@shared/services';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ResourcesUtils } from '../../modules/graph-editor/utils/resource-utils';
import type { ResourceNav } from '../../modules/resources-management/models/resource-nav';
import { WorkflowLoggerService } from '../../modules/graph-editor/services/workflow-logger.service';
import type {
  IUpdateWorkflowPayload,
  IWorkflowListResponse,
  IWorkflowResponse,
  IWorkflowRevisionResponse,
  WorkflowResource,
  WorkflowResources,
} from './workflow-gateway.type';
import { EResourceType } from './workflow-gateway.type';
import type { IWorkflowLog } from '../../modules/graph-editor/models/workflow-log';
import { cBaseAgentGraph } from './templates/cBaseAgentGraph';
import { cManifest } from './templates/cManifest';
import { EXPERIMENTAL_FEATURE } from '@shared/enums';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';
import { FeatureFlags } from '@shared/services/feature-flag/feature-flags';
import type { Workflow } from '@dronedeploy/rocos-js-sdk';
@Injectable({
  providedIn: 'root',
})
export class WorkflowGatewayService {
  public useNewRoute$: Observable<boolean> = this.featureFlags.featureFlags$.pipe(
    map((flags) => flags[FeatureFlags.ProfileFlows]),
    distinctUntilChanged(),
  );

  public token: string;
  private uri = environment.api.url;

  public constructor(
    private httpService: HttpClient,
    private rocosClient: RocosClientService,
    private sdk: RocosSdkClientService,
    private workflowLogger: WorkflowLoggerService,
    private appService: AppService,
    private userService: UserService,
    private projectPermissionService: ProjectPermissionService,
    private featureFlags: FeatureFlagService,
  ) {
    this.rocosClient.token$.subscribe((token) => (this.token = token));
  }

  public updateWorkflow(flowId: string, payload: IUpdateWorkflowPayload): Observable<any> {
    const headers = this.getHeaders();
    return this.getWorkflow(flowId).pipe(
      take(1),
      switchMap((response) => {
        return this.httpService.put(
          `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}`,
          { ...payload, resources: this.defaultResourcesData(response.resources) },
          {
            headers,
          },
        );
      }),
    );
  }

  public updateWorkflowResources(resources: WorkflowResources, flowId: string): Observable<any> {
    const headers = this.getHeaders();
    const payload: {
      resources: WorkflowResources;
    } = {
      resources: this.defaultResourcesData(resources),
    };

    return this.httpService
      .put(`${this.uri}/projects/${this.appService.projectId}/flows/${flowId}/resources`, payload, { headers })
      .pipe(tap(() => this.workflowLogger.addLogMsg(`Resources updated for flow: ${flowId}.`)));
  }

  public updateSingleWorkflowResource(resource: WorkflowResource, flowId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.httpService
      .put(
        `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}/resources/${resource.path}`,
        this.defaultResourceData(resource),
        {
          headers,
        },
      )
      .pipe(tap(() => this.workflowLogger.addLogMsg(`Resource ${resource.path} updated for flow: ${flowId}.`)));
  }

  public createNewWorkflow(payload: IUpdateWorkflowPayload): Observable<IWorkflowResponse> {
    const headers = this.getHeaders();

    payload.resources = {
      // [ResourcesUtils.Javascript]: {
      //   data:  This is pulled from here https://github.com/team-rocos/common-flow-resources/tree/next/std-nodes by Chewie
      //   type: EResourceType.javascript,
      // },
      // [ResourcesUtils.NodesDef]: {
      //   data: This is pulled from here https://github.com/team-rocos/common-flow-resources/tree/next/std-nodes by Chewie
      //   type: EResourceType.nodeDefinition,
      // },
      [ResourcesUtils.Manifest]: {
        data: JSON.stringify(cManifest, null, 4),
        type: EResourceType.manifest,
      },
      [ResourcesUtils.AgentGraphPath]: {
        data: JSON.stringify(cBaseAgentGraph),
        type: EResourceType.agentGraph,
      },
    };

    return this.httpService
      .post<IWorkflowResponse>(`${this.uri}/projects/${this.appService.projectId}/flows`, payload, { headers })
      .pipe(tap(() => this.workflowLogger.addLogMsg(`New Flow ${payload.name} created.`)));
  }

  public getWorkflow(flowId: string): Observable<IWorkflowResponse> {
    const headers = this.getHeaders();
    return this.httpService.get<IWorkflowResponse>(
      `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}`,
      {
        headers,
      },
    );
  }

  public getWorkflowRevisions(flowId: string, limit = 100): Observable<IWorkflowRevisionResponse[]> {
    const headers = this.getHeaders();
    return this.httpService.get<IWorkflowRevisionResponse[]>(
      `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}/revisions?limit=${limit}`,
      {
        headers,
      },
    );
  }

  public revertToWorkflowRevision(flowId: string, revisionId: string): Observable<IWorkflowResponse> {
    const headers = this.getHeaders();
    return this.httpService.post<IWorkflowResponse>(
      `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}/revert?revision=${revisionId}`,
      {},
      {
        headers,
      },
    );
  }

  public getWorkflowList(): Observable<IWorkflowListResponse[]> {
    const headers = this.getHeaders();
    return this.httpService
      .get<IWorkflowListResponse[]>(`${this.uri}/projects/${this.appService.projectId}/flows`, {
        headers,
      })
      .pipe(
        withLatestFrom(
          this.projectPermissionService.checkPermission(this.appService.projectId, EXPERIMENTAL_FEATURE.CloudFlow),
        ),
        map(([res, cloudFlowFlag]) => {
          if (this.userService.isAdmin() || cloudFlowFlag) return res;
          return res.filter((workflow) => {
            return workflow.type !== 'cloud';
          });
        }),
      );
  }

  public getOneResource(resource: ResourceNav, flowId: string): Observable<WorkflowResource> {
    const headers = this.getHeaders();
    return this.httpService.get<WorkflowResource>(
      `${this.uri}/projects/${this.appService.projectId}/flows/${flowId}/resources/${
        resource.getWorkflowResource().path
      }`,
      { headers },
    );
  }

  public deleteWorkflow(flowId: string): Observable<void> {
    const headers = this.getHeaders();
    return this.httpService.delete<void>(`${this.uri}/projects/${this.appService.projectId}/flows/${flowId}`, {
      headers,
    });
  }

  public loadWorkflowDefinition(flowId: string, callsign: string, resources: WorkflowResources): Observable<any> {
    const payload = {
      [flowId]: {
        resources,
      },
    };

    return this.rocosClient.callService(
      this.appService.projectId,
      callsign,
      '/flow-engine/loadDefinitions',
      JSON.stringify(payload),
    );
  }

  public async getDeployedFlows(callsign: string, profileId: string): Promise<Workflow[]> {
    const useNewRoute = (await firstValueFrom(this.useNewRoute$)) ?? false;

    if (!useNewRoute) {
      const headers = this.getHeaders();
      return firstValueFrom(
        this.httpService.get<Workflow[]>(
          `${this.uri}/projects/${this.appService.projectId}/robots/${callsign}/automate/flows/deployments`,
          { headers },
        ),
      );
    }

    const deployed = await this.sdk.client
      .getWorkflowService()
      .getDeployedWorkflows(this.appService.projectId, profileId, callsign);

    const dedupedMap = new Map<string, Workflow>();
    for (const workflow of deployed) {
      const workflowId = workflow.flowId;

      if (!dedupedMap.has(workflowId)) {
        dedupedMap.set(workflowId, workflow);
        continue;
      }

      if (workflow.type === 'profile') {
        // If the workflow is deployed as a profile and a robot, we only want to keep the profile deployment
        dedupedMap.set(workflowId, workflow);
      }
    }

    return Array.from(dedupedMap.values());
  }

  public publishFlows(callsign: string, flows: string[]): Promise<void> {
    return this.sdk.client.getWorkflowService().updateRobotDeployments(this.appService.projectId, callsign, flows);
  }

  public subscribeLogs(callsign: string, flowId: string, instanceId: string = null): Observable<IWorkflowLog> {
    return this.rocosClient
      .subscribeV2(
        this.appService.projectId,
        [callsign],
        [`/flow-engine/flows/${flowId}/${instanceId ? instanceId : flowId}/logs?dqp=FIFO&ttl=1s`],
      )
      .pipe(map((msg) => (msg ? msg.payload : null)));
  }

  public getAffectedRobots(flowId: string): Promise<{
    callsigns: string[];
    profileIds: string[];
  }> {
    return this.sdk.client.getWorkflowService().getAffectedRobots(this.appService.projectId, flowId);
  }

  public syncDeployments(flowId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.httpService.post<string[]>(
      `${this.uri}/projects/${this.appService.projectId}/automate/flows/${flowId}/deployments/_sync`,
      {},
      {
        headers,
      },
    );
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      authorization: `Bearer ${this.token}`,
    });
  }

  private defaultResourcesData = (resources: WorkflowResources): WorkflowResources => {
    const newResources: WorkflowResources = {};
    Object.entries(resources).forEach(([key, resource]) => {
      newResources[key] = this.defaultResourceData(resource);
    });
    return newResources;
  };

  // Note: Empty string is not valid data for the API; default to ' '
  private defaultResourceData = (resource: WorkflowResource): WorkflowResource => {
    return { ...resource, data: resource.data || ' ' };
  };
}
