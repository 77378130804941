import { ResourcesUtils } from 'src/app/workflow/modules/graph-editor/utils/resource-utils';
import type { IManifest } from '../workflow-gateway.type';

export const cManifest: IManifest = {
  signals: {
    main: {
      entry: [ResourcesUtils.AgentGraphName, 'Entry'],
      args: [],
    },
  },
  resources: [
    {
      path: 'std-nodes.js',
      provider: 'js',
      runtime: '',
      options: [],
    },
    {
      path: 'std-nodes.json',
      provider: 'visualscript',
      runtime: '',
      options: [],
    },
    {
      path: ResourcesUtils.AgentGraphPath,
      provider: 'visualscript',
      runtime: '',
      options: [],
    },
  ],
};
